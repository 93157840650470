// External dependencies
import { useState } from 'react';
import { useForm } from '@formspree/react';
import { useTranslation } from 'react-i18next';

// Internal Modules

// Internal Components

// Styles

// Constants

const Form = () => {
  const { t } = useTranslation();
  const [state, handleSubmit] = useForm('contact');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Clean existing error messages
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = t('Form.errors.name');
    if (!formData.email.trim()) {
      newErrors.email = t('Form.errors.email.required');
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('Form.errors.email.invalid');
    }
    if (!formData.message.trim()) newErrors.message = t('Form.errors.message');
    return newErrors;
  };

  const customHandleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      const result = await handleSubmit(formData);
      if (!result.error) {
        // Clear form
        setFormData({ name: '', email: '', message: '' });
      }
    }
  };

  return (
    <section id="contact-form" className="section-form my-5 pt-5">
      <div className="container d-flex justify-content-center">
        <div className="form-container py-5 px-4 px-md-5">
          {state.succeeded ? (
            <div className="text-center">
              <h2>
                <span className="text-green">¡</span>
                {t('Form.success.title')}
                <span className="text-green">!</span>
              </h2>
              <p>{t('Form.success.message')}</p>
            </div>
          ) : (
            <>
              <h2>{t('Form.title')}</h2>
              <p className="mb-5">{t('Form.description')}</p>
              <form onSubmit={customHandleSubmit}>
                {errors.name && (
                  <small className="text-green">{errors.name}</small>
                )}
                <input
                  name="name"
                  type="text"
                  className={`feedback-input ${errors.name ? 'error-input' : ''}`}
                  placeholder={t('Form.placeholders.name')}
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.email && (
                  <small className="text-green">{errors.email}</small>
                )}
                <input
                  name="email"
                  type="email"
                  className={`feedback-input ${errors.email ? 'error-input' : ''}`}
                  placeholder={t('Form.placeholders.email')}
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.message && (
                  <small className="text-green">{errors.message}</small>
                )}
                <textarea
                  name="message"
                  className={`feedback-input ${errors.message ? 'error-input' : ''}`}
                  placeholder={t('Form.placeholders.message')}
                  value={formData.message}
                  onChange={handleChange}
                />
                <input
                  type="submit"
                  disabled={state.submitting}
                  value={t('Form.submit')}
                />
              </form>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Form;

// External dependencies
// import { useTranslation } from 'react-i18next';

// Internal Modules
import OurWork from 'assets/ourwork.webp';
import Smiley from 'assets/smiley.webp';

// Internal Components
import { InfiniteSlider } from 'components';

// Constants

const SLIDES = [
  { url: '/images/projects/eth.webp', name: 'eth' },
  { url: '/images/projects/fractalapp.webp', name: 'fractalapp' },
  { url: '/images/projects/giged.webp', name: 'giged' },
  { url: '/images/projects/laurel-app.webp', name: 'laurel-app' },
  { url: '/images/projects/lenderlink.webp', name: 'lenderlink' },
  { url: '/images/projects/loco-app.webp', name: 'loco-app' },
];

const Clients = () => (
  // const { i18n } = useTranslation();
  // const currentLanguage = i18n.language;

  <section className="section-our-work pt-4">
    <div className="mt-5 pt-5">
      <div className="col-12 text-center position-relative">
        <div className="our-work-sticker position-absolute">
          <img className="img-fluid" src={OurWork} alt="Our Work Sticker" />
        </div>

        <div className="smiley-sticker position-absolute">
          <img className="img-fluid" src={Smiley} alt="Smiley Sticker" />
        </div>

        <InfiniteSlider slides={[...SLIDES, ...SLIDES]} />
      </div>
    </div>
  </section>
);
export default Clients;

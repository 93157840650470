// External dependencies
import Gloory from 'assets/gloory.webp';
import Service1 from 'assets/service-1.webp';
import Service2 from 'assets/service-2.webp';
import Service3 from 'assets/service-3.webp';
import { useTranslation } from 'react-i18next';

// Internal Modules
import { Reveal } from 'components';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="section-about-us container my-5">
      <div className="row my-5">
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
          <img
            src={Gloory}
            className="img-fluid gloorio-sticker"
            alt="Gloorio"
          />
        </div>

        <div className="col-12 col-md-6 pt-md-5 px-4">
          <Reveal hideSlideAnimation>
            <h2 className="section-heading mt-md-5">
              {t('AboutUs.heading')} <br />
              <span className="text-purple">{t('AboutUs.headingSpan')}</span>
            </h2>
          </Reveal>
          <Reveal hideSlideAnimation>
            <p className="pt-3">{t('AboutUs.firstParagraph')}</p>
          </Reveal>
          <Reveal hideSlideAnimation>
            <p>{t('AboutUs.secondParagraph')}</p>
          </Reveal>
        </div>
      </div>

      <div className="row my-5">
        <div className="col-12 col-md-4 pt-3 text-center d-flex flex-column align-items-center">
          <Reveal hideSlideAnimation>
            <div className="img-container p-4">
              <img
                src={Service1}
                className="img-fluid"
                alt="Gloory service 1"
              />
            </div>
          </Reveal>
          <Reveal hideSlideAnimation>
            <h3 className="pt-4">{t('AboutUs.mobileAppsTitle')}</h3>
          </Reveal>
          <Reveal hideSlideAnimation>
            <p className="pt-2">{t('AboutUs.mobileAppsDescription')}</p>
          </Reveal>
        </div>
        <div className="col-12 col-md-4 text-center d-flex flex-column align-items-center">
          <Reveal hideSlideAnimation>
            <div className="img-container p-4">
              <img
                src={Service2}
                className="img-fluid"
                alt="Gloory service 1"
              />
            </div>
          </Reveal>
          <Reveal hideSlideAnimation>
            <h3 className="pt-4">{t('AboutUs.webSolutionsTitle')}</h3>
          </Reveal>
          <Reveal hideSlideAnimation>
            <p className="pt-2">{t('AboutUs.webSolutionsDescription')}</p>
          </Reveal>
        </div>
        <div className="col-12 col-md-4 pt-2 text-center d-flex flex-column align-items-center">
          <Reveal hideSlideAnimation>
            <div className="img-container p-4">
              <img
                src={Service3}
                className="img-fluid"
                alt="Gloory service 1"
              />
            </div>
          </Reveal>
          <Reveal hideSlideAnimation>
            <h3 className="pt-4">{t('AboutUs.highPerformanceTitle')}</h3>
          </Reveal>
          <Reveal hideSlideAnimation>
            <p className="pt-2">{t('AboutUs.highPerformanceDescription')}</p>
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

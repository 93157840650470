const InfiniteSlider = ({ slides }) => (
  <div>
    <div className="infinite-slider">
      <div className="infinite-slider-wrapper">
        <div className="infinite-slider-slide">
          {slides.map((slide, index) => (
            <div key={index} className="infinite-slider-image">
              <img src={slide.url} alt={slide.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default InfiniteSlider;

// External dependencies
import { Slider } from 'components';
import { useTranslation } from 'react-i18next';

// Internal Modules

// Internal Components

// Styles

// Constants
const getSliderData = (t) => [
  {
    client: () => <>{t('Testimonials.sliderData.Mycarmx.name')}</>,
    testimonial: () => (
      <h3 className="pb-3">
        {t('Testimonials.sliderData.Mycarmx.testimonial.part1')}
        <br />
        <span className="text-green">
          {t('Testimonials.sliderData.Mycarmx.testimonial.part2')}
        </span>
      </h3>
    ),
    description: t('Testimonials.sliderData.Mycarmx.description'),
  },
  {
    client: () => <>{t('Testimonials.sliderData.Oxford.name')}</>,
    testimonial: () => (
      <h3 className="pb-3">
        {t('Testimonials.sliderData.Oxford.testimonial.part1')}{' '}
        <span className="text-green">
          {t('Testimonials.sliderData.Oxford.testimonial.part2')}
        </span>
      </h3>
    ),
    description: t('Testimonials.sliderData.Oxford.description'),
  },
  {
    client: () => <>{t('Testimonials.sliderData.AppLoCo.name')}</>,
    testimonial: () => (
      <h3 className="pb-3">
        {t('Testimonials.sliderData.AppLoCo.testimonial.part1')}
        <br />
        <span className="text-green">
          {t('Testimonials.sliderData.AppLoCo.testimonial.part2')}
        </span>
      </h3>
    ),
    description: t('Testimonials.sliderData.AppLoCo.description'),
  },
];

const Slide = ({ slide, index }) => (
  <div
    key={index}
    className="slide px-5 d-flex justify-content-center flex-column text-center p-3"
  >
    <div className="text-start px-md-5">
      {slide.testimonial()}
      <p className="m-0 pb-1 sarabun-semibold">{slide.client()}</p>
      <p className="m-0 pb-1 sarabun-light ">{slide.description}</p>
    </div>
  </div>
);

const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <section className="section-testimonials container my-5 pb-5">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 text-center">
          <h2 className="section-heading pb-5 text-center">
            {t('Testimonials.heading')}
          </h2>

          <Slider className="position-relative">
            {getSliderData(t).map((slide, index) => (
              <div className="position-relative" key={index}>
                <Slide slide={slide} index={index} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

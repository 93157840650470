// External dependencies
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { FormspreeProvider } from '@formspree/react';
import 'bootstrap/dist/css/bootstrap.css';

// Internal Modules
import './utils/i18n.js';

// Internal Components
import App from './App';

// Styles
import './App.scss';

// Constants
const FORMSPREE_PROJECT_ID = '2491076776692809529';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <Analytics />
    <SpeedInsights />
    <FormspreeProvider project={FORMSPREE_PROJECT_ID}>
      <App />
    </FormspreeProvider>
  </StrictMode>,
);

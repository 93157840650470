import {
  AboutUs,
  Divider,
  Footer,
  MainContent,
  Services,
  Testimonials,
  Form,
} from 'components';

function App() {
  return (
    <div className="main-container">
      <MainContent />
      <AboutUs />
      <Divider />
      <Services />
      <Divider inverse />
      <Testimonials />
      <Form />
      <Footer />
    </div>
  );
}

export default App;

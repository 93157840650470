import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend) // Usa HttpBackend para cargar las traducciones desde archivos externos.
  .use(LanguageDetector) // Usa LanguageDetector para detectar automáticamente el idioma del usuario.
  .use(initReactI18next) // Inicializa react-i18next para integrar i18next con React.
  .init({
    // Configuración del HttpBackend.
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Especifica la ruta de los archivos de traducción.
    },

    // Configuración de LanguageDetector.
    detection: {
      order: ['querystring', 'navigator'], // Solo querystring y luego el navegador.
      lookupQuerystring: 'lng', // Parámetro de la URL para especificar el idioma.
      caches: [], // No cachear para evitar conflictos.
    },

    // Idioma por defecto.
    fallbackLng: 'es', // Español como idioma predeterminado.
    debug: true, // Habilita el modo de depuración para mostrar información de diagnóstico en la consola.

    interpolation: {
      escapeValue: false, // Desactiva el escape de valores, no necesario en React ya que evita XSS por defecto.
    },
  });

export default i18n;

// External dependencies
import { useTranslation } from 'react-i18next';

// Internal Modules

// Internal Components
import Reveal from './Reveal';

// Styles

// Constants

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="d-flex align-items-center justify-content-center flex-column py-5">
      <Reveal>
        <span>
          © {new Date().getFullYear()} Gloory. {t('Footer.rights')}
        </span>
      </Reveal>
    </footer>
  );
};

export default Footer;

// External dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// Internal Modules
import Logo from '../assets/logo.svg';
import Button from './Button';
import { scrollToSection } from '../utils';

// Styles

// Constants

const Header = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)',
  });

  return (
    <header className="container d-flex justify-content-center justify-content-md-between">
      <div className="logo-container">
        <img src={Logo} alt={t('Header_alt_logo')} />
      </div>
      {isDesktop && (
        <nav>
          <ul className="list-inline">
            <li className="list-inline-item ms-5">
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  scrollToSection('services');
                }}
              >
                {t('Header_services')}
              </Button>
            </li>
            <li className="list-inline-item ms-5">
              <Button
                className="btn btn-primary"
                onClick={() => {
                  scrollToSection('contact-form');
                }}
              >
                {t('Header_contact_now')}
              </Button>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;

import { useRef } from 'react';
import SlickSlider from 'react-slick';

const Slider = ({ children }) => {
  const slider = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="slider position-relative">
      <SlickSlider {...settings} ref={slider}>
        {children}
      </SlickSlider>
      <button
        className="left-arrow-button position-absolute"
        onClick={() => slider?.current?.slickPrev()}
      >
        <i className="arrow left" />
      </button>
      <button
        className="right-arrow-button position-absolute"
        onClick={() => slider?.current?.slickNext()}
      >
        <i className="arrow right" />
      </button>
    </div>
  );
};

export default Slider;

import Divider1 from 'assets/divider-1.webp';
import Divider2 from 'assets/divider-2.webp';

const Divider = ({ inverse = false }) => {
  const src = inverse ? Divider2 : Divider1;

  return (
    <div>
      <img src={src} className="img-fluid" alt="Page sections divider" />
    </div>
  );
};

export default Divider;

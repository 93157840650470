// External dependencies
import { useTranslation } from 'react-i18next';

// Internal Modules
import { scrollToSection } from 'utils';

// Internal Components
import Star from 'assets/star.svg';
import { Button, Header, OurWork, Reveal } from 'components';

// Styles

// Constants

const SatisfiedEntrepreneurs = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column align-items-center mt-5">
      <div className="stars">
        {[...Array(5)].map((_, i) => (
          <img key={i} src={Star} alt="star-icon" />
        ))}
      </div>
      <p className="text-center pt-2">
        {t('MainContent.satisfiedEntrepreneurs.text1')}
        <br />
        {t('MainContent.satisfiedEntrepreneurs.text2')}
      </p>
    </div>
  );
};

const Cta = () => {
  const { t } = useTranslation();
  return (
    <div className="cta-container container-fluid">
      <div className="row">
        <div className="col-12 d-flex justify-content-center align-items-center flex-column text-center">
          <SatisfiedEntrepreneurs />

          <Reveal hideSlideAnimation>
            <h1 className="main-heading pt-4 d-flex flex-column align-items-center">
              {t('MainContent.cta.mainHeading')}
              <span className="text-green">
                {t('MainContent.cta.mainHeadingSpan')}
              </span>
            </h1>
          </Reveal>

          <Reveal>
            <h4 className="subtitle pt-2">
              {t('MainContent.cta.subtitle1')}
              <br />
              {t('MainContent.cta.subtitle2')}
            </h4>
          </Reveal>
        </div>

        <div className="text-center">
          <Button
            className="btn btn-primary mt-4"
            onClick={() => {
              scrollToSection('contact-form');
            }}
          >
            {t('MainContent.cta.contactNow')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const MainContent = () => (
  <section className="main-content pt-5">
    <Header />
    <Cta />
    <OurWork />
  </section>
);

export default MainContent;

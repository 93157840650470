// External dependencies
import Service1 from 'assets/uxuidesign.webp';
import Service2 from 'assets/web.webp';
import Service3 from 'assets/mobileapps.webp';
import { useTranslation } from 'react-i18next';

// Internal Modules
import { Reveal } from 'components';

const Services = () => {
  const { t } = useTranslation();

  return (
    <section id="services" className="section-services container my-5">
      <div className="row">
        <div className="col-12 col-md-6 offset-md-3 text-center">
          <Reveal>
            <h2 className="section-heading pb-3">
              {t('Services.sectionHeading')}
            </h2>
          </Reveal>
          <Reveal hideSlideAnimation>
            <p>{t('Services.firstParagraph')}</p>
            <p className="pb-4">{t('Services.secondParagraph')}</p>
          </Reveal>
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-12 col-md-4">
          <Reveal hideSlideAnimation>
            <div className="service-box mb-4">
              <img
                src={Service1}
                className="img-fluid"
                alt="Gloory service 1"
              />
              <h3 className="service-box__text">{t('Services.uxuiDesign')}</h3>
            </div>
          </Reveal>
        </div>
        <div className="col-12 col-md-4">
          <Reveal hideSlideAnimation>
            <div className="service-box mb-4">
              <img
                src={Service2}
                className="img-fluid"
                alt="Gloory service 2"
              />
              <h3 className="service-box__text">
                {t('Services.webDevelopment')}
              </h3>
            </div>
          </Reveal>
        </div>
        <div className="col-12 col-md-4">
          <Reveal hideSlideAnimation>
            <div className="service-box mb-4">
              <img
                src={Service3}
                className="img-fluid"
                alt="Gloory service 3"
              />
              <h3 className="service-box__text">{t('Services.mobileApps')}</h3>
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};

export default Services;
